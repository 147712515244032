import React, { createContext } from "react";
import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

export let ThemeContext = createContext(null);
function App() {
  const [cartItems, setCartItems] = React.useState([]);
  const [cart, setCart] = React.useState([]);
  return (
    <ThemeContext.Provider value={{ cartItems, setCartItems, cart, setCart }}>
      <div className=" font-Kanit h-screen">
        <ScrollRestoration />
        <Outlet />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
