import React, { useState } from "react";
import { PrevIcon } from "../../icons";
import Button from "../../components/common/button";
import { PaymentModal } from "../../components/modals";
import { Link } from "react-router-dom";
import { ItemsCard } from "../Invoice";
import { ThemeContext } from "../../App";
import Head from "../../components/header/head";
import Menucontainer from "../../components/Layout/menucontainer";
import { twMerge } from "tailwind-merge";

function EditInvoice() {
  const [modal, setModal] = useState(false);
  let { cart, setCart } = React.useContext(ThemeContext);

  function addItem(id) {
    let selectedMeals = cart.find((product) => product.id === id);

    // setCartItems((initialItemms) => [selectedMeals, ...initialItemms]);
    // console.log(cartItems);
    setCart((initialItems) => {
      if (initialItems.find((item) => item.id === id) == null) {
        return [...initialItems, selectedMeals];
      } else {
        return initialItems.map((item) => {
          if (item.id === id) {
            return { ...item, qty: item.qty + 1 };
          } else {
            return item;
          }
        });
      }
    });
    // console.log(data.filter((product) => product.id === id));
  }

  function removeItem(id) {
    setCart((initialItems) => {
      if (initialItems.find((item) => item.id === id)?.qty === 1) {
        return initialItems.filter((item) => item.id !== id);
      } else {
        return initialItems.map((item) => {
          if (item.id === id) {
            return { ...item, qty: item.qty - 1 };
          } else {
            return item;
          }
        });
      }
    });
  }
  function hideModal() {
    setModal(false);
  }
  function showModal() {
    setModal(true);
  }

  const subtotal = cart.reduce((acc, item) => {
    acc += item.qty * item.price;
    return acc;
  }, 0);
  const vat = (7.5 / 100) * subtotal;
  const total = Math.floor(vat + subtotal);
  const full = twMerge("min-h-[284px] h-fit");
  const empty = twMerge("h-[424px]");
  return (
    <Menucontainer>
      <div className=" w-full bg-[#F7F7F7] pt-12 relative">
        <Head
          pageTitle={"Foodswiipe Menu | Edit-invoice"}
          metaDescription={"Foodswiipe Menu | Edit-invoice"}
        />
        <div className=" w-full bg-[#F7F7F7] px-[6.106%]">
          <div className=" w-full flex items-center gap-4 mb-4">
            <Link to="/invoice">
              <div className=" h-11 w-11 bg-[#FFF0EB] rounded-2xl flex items-center justify-center">
                <PrevIcon />
              </div>
            </Link>
            <p className="text-black font-bold text-3xl">Edit Invoice</p>
          </div>
          <div
            className={`${
              cart.length <= 0 ? empty : full
            } bg-white w-full flex flex-col gap-4 p-6 rounded-xl mb-6`}
          >
            {cart &&
              cart.map((item) => {
                return (
                  <ItemEdit
                    itemName={item.name}
                    itemQty={item.qty}
                    itemPrice={`N${item.price * item.qty}`}
                    addClick={() => addItem(item.id)}
                    subClick={() => removeItem(item.id)}
                  />
                );
              })}
          </div>

          <div className=" bg-white p-6 rounded-2xl mb-4">
            <div className=" flex flex-col gap-2">
              <ItemsCard itemName={"Subtotal"} itemPrice={`N${subtotal}`} />
              <ItemsCard itemName={"VAT (7.5%)"} itemPrice={`N${vat}`} />
              <div className=" flex justify-between pt-2 border-t border-solid border-[rgba(0, 0, 0, 0.1)]">
                <p className=" font-semibold">Total</p>
                <p className=" font-semibold">{`N${total}`}</p>
              </div>
            </div>
          </div>
          <Link to="/invoice">
            <div className=" flex w-full gap-4 items-center py-6">
              <Button
                type={"button"}
                title="Update"
                variant="primary"
                onClick={showModal}
              />
            </div>
          </Link>
        </div>

        {modal ? (
          <div className="w-full fixed bottom-0 left-0 right-0 ">
            <PaymentModal click={hideModal} />
          </div>
        ) : null}
      </div>
    </Menucontainer>
  );
}

export default EditInvoice;

export function ItemEdit({ itemName, itemQty, itemPrice, addClick, subClick }) {
  return (
    <div className=" w-full flex flex-col gap-2">
      <p className=" font-light">{itemName}</p>
      <div className=" flex items-center justify-between w-full">
        <div className=" flex gap-3">
          <div
            onClick={subClick}
            className=" w-6 h-6 flex justify-center items-center rounded-full border border-solid border-[rgba(0, 0, 0, 0.1)]"
          >
            <img src="../../../images/minus.png" alt="minus" />
          </div>
          <p className=" w-6 h-6 flex justify-center items-center rounded-full border border-solid border-[rgba(0, 0, 0, 0.1)]">{`x${itemQty}`}</p>
          <div
            onClick={addClick}
            className=" w-6 h-6 flex justify-center items-center rounded-full border border-solid border-[rgba(0, 0, 0, 0.1)]"
          >
            <img src="../../../images/plus.png" alt="plus" />
          </div>
        </div>
        <p>{itemPrice}</p>
      </div>
    </div>
  );
}
