import React from "react";
import { twMerge } from "tailwind-merge";

function Button({
  type,
  title,
  onClick,
  variant,
  disabled,
  className,
  icon,
  loading,
}) {
  const primaryBtn = twMerge("bg-[#F5853F] text-white", className);
  const secondaryBtn = twMerge("bg-[#FFECE5] text-[#F2650D] ", className);
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${
        variant === "secondary" ? secondaryBtn : primaryBtn
      } w-full font-medium rounded-2xl h-14`}
    >
      {title} {icon && icon} {loading && "loading ...."}
    </button>
  );
}

export default Button;
