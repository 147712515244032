import React from 'react'

const ErrorPage = ({message}) => {
  return (
    <div className="w-[80%] mx-auto flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold">Error</h1>
      <p className="text-xl mt-4 text-center">{message}</p>
    </div>
  )
}

export default ErrorPage