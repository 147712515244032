import React from "react";

function Menucontainer({ children }) {
  return (
    <>
      <div className=" w-full max-w-md mx-auto h-full max-h-full">
        {children}
      </div>
      ;
    </>
  );
}

export default Menucontainer;
