import React, { useState } from "react";
import { PrevIcon } from "../../icons";
import Button from "../../components/common/button";
import { PaymentModal } from "../../components/modals";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Menucontainer from "../../components/Layout/menucontainer";
import Head from "../../components/header/head";
import { createOrder } from "../../services/request";
import { twMerge } from "tailwind-merge";

function Invoice() {
  const [modal, setModal] = useState(false);
  let { cart } = React.useContext(ThemeContext);
  const [instructions, setInstructions] = useState("");
  const [loading, setLoading] = useState(false);
  const full = twMerge("min-h-[184px] h-fit");
  const empty = twMerge("h-[184px]");

  function hideModal() {
    setModal(false);
  }

  function showModal() {
    setModal(true);
  }

  const bizId = localStorage.getItem("bizId");
  const tableId = localStorage.getItem("tableId");

  const CartItem = [];

  cart.map((item) =>
    CartItem.push({ foodId: item?.id, quantity: item?.qty, tableId: tableId })
  );

  async function makeOrder(reference) {
    try {
      setLoading(true);
      await createOrder({
        additionalInstructions: instructions,
        businessId: bizId,
        orderitems: CartItem,
        reference : reference
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const subtotal = cart.reduce((acc, item) => {
    acc += item?.qty * item?.price;
    return acc;
  }, 0);

  const vat = (7.5 / 100) * subtotal;
  const total = Math.floor(vat + subtotal);
  return (
    <Menucontainer>
      <div className=" w-full bg-[#F7F7F7]  rounded-3xl pt-12 relative">
        <Head
          pageTitle={"Foodswiipe Menu | Invoice"}
          metaDescription={"Foodswiipe Menu | Invoice"}
        />
        <div className=" w-full bg-[#F7F7F7] px-[6.106%]">
          <div className=" w-full flex items-center gap-4 mb-4">
            <Link to="/menu">
              <div className=" h-11 rounded-2xl w-11 bg-[#FFF0EB]  flex items-center justify-center">
                <PrevIcon />
              </div>
            </Link>
            <p className="text-black font-bold text-3xl">Invoice</p>
          </div>
          <div className=" bg-white p-6 rounded-2xl mb-6">
            <div className=" w-full flex items-center justify-between mb-2">
              <p className=" font-semibold">Cart Items</p>
              <Link to={"/edit-invoice"}>
                <p className=" font-semibold text-[#F5853F]">Edit Cart</p>
              </Link>
            </div>
            <div
              className={` ${
                cart.length <= 0 ? empty : full
              } flex flex-col gap-2 pt-4 border-t border-solid border-[rgba(0, 0, 0, 0.1)]`}
            >
              {cart.map((item, id) => {
                return (
                  <ItemsCard
                    key={id}
                    itemName={item?.name}
                    itemQty={item?.qty}
                    cartList={true}
                    itemPrice={`N${item?.price * item?.qty}`}
                    id={id}
                  />
                );
              })}
            </div>
          </div>
          <form className="w-full flex flex-col gap-6 mb-6 outline-none">
            <div className="w-full flex flex-col relative">
              <label className=" text-sm font-normal absolute -top-3 left-4">
                Additional Instruction
              </label>
              <textarea
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                placeholder={"Write a review"}
                className={` bg-[#FFFFFF] rounded-lg w-full h-32 p-4 outline-none`}
              ></textarea>
            </div>
          </form>
          <div className=" bg-white p-6 rounded-2xl mb-6">
            <div className=" flex flex-col gap-2">
              <ItemsCard itemName={"Subtotal"} itemPrice={`N${subtotal}`} />
              <ItemsCard itemName={"VAT (7.5%)"} itemPrice={`N${vat}`} />
              <div className=" flex justify-between pt-2 border-t border-solid border-[rgba(0, 0, 0, 0.1)]">
                <p className=" font-semibold">Total</p>
                <p className=" font-semibold">{`N${total}`}</p>
              </div>
            </div>
          </div>

          {/* <div className=" flex flex-col gap-2">
            <OrderStatus
              info={"Order Status"}
              details={"Your order is confirmed"}
            />
            <OrderStatus info={"Table Number"} details={"Table TB12"} />
            <OrderStatus info={"Customer Name"} details={"Gilbert"} />
          </div> */}
        </div>
        <div className="bg-white px-[6.106%] flex w-full gap-4 items-center py-6">
          <Button
            type={"button"}
            title={loading ? "Placing Order" : "Place Order"}
            variant="primary"
            onClick={showModal}
          />
        </div>
        {modal ? (
          <div className="w-full fixed bottom-0 left-0 right-0 ">
            <PaymentModal makeOrder={makeOrder} click={hideModal} amount={total} />
          </div>
        ) : null}
      </div>
    </Menucontainer>
  );
}

export default Invoice;

export const ItemsCard = ({ itemName, itemQty, cartList, itemPrice, id }) => {
  return (
    <div className=" flex justify-between" key={id}>
      <p className=" text-base font-light">{itemName}</p>
      <div className=" flex items-center gap-8">
        {cartList ? (
          <p className=" text-base font-light">x{itemQty}</p>
        ) : (
          <p className=" text-base font-light">{itemQty}</p>
        )}
        <p>{itemPrice}</p>
      </div>
    </div>
  );
};

export const OrderStatus = ({ info, details }) => {
  return (
    <div>
      <p className=" font-light text-sm text-[#222222] opacity-50">{info}</p>
      <p>{details}</p>
    </div>
  );
};
