import React, { useEffect } from "react";
import Button from "../../components/common/button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Head from "../../components/header/head";
import Menucontainer from "../../components/Layout/menucontainer";

function Scan() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  useEffect(() => {
    let params = [];
    for (let entry of searchParams.entries()) {
      params.push(entry);
    }

    if(params.length === 0){
      navigate("/error", {replace : true})
    } else {
      const bizId = params[0]?.[1]
      const tableId = params[1]?.[1]

      if(!bizId || bizId === "undefined" || !tableId || tableId === "undefined"){
        navigate("/error", {replace : true})
      } else {
        localStorage.setItem("bizId", bizId);
        localStorage.setItem("tableId", tableId);
      }
    }
  }, [searchParams, navigate])
  

  return (
    <Menucontainer>
      <div className="w-full flex flex-col items-center rounded-3xl justify-center bg-[#F7F7F7] pt-28">
        <Head
          pageTitle={"Foodswiipe Menu | QRScan"}
          metaDescription={"Foodswiipe Menu | QRScan"}
        />
        <div className="  w-full flex flex-col gap-8 items-center justify-center px-[6.106%] pb-6">
          <p className="text-2xl">Scan QR Code</p>
          <div className="bg-[#1E1E1E] rounded-2xl w-full h-[342px]"></div>
        </div>
        <div className="bg-white rounded-t-3xl px-[6.106%] mt-36 self-end flex w-full gap-4 items-center py-6">
          <Link to="/register" className="w-full">
            <Button type={"button"} title="Open Link" variant="primary" />
          </Link>
        </div>
      </div>
    </Menucontainer>
  );
}

export default Scan;
