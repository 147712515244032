import React from "react";
import { DownVector } from "../../icons";

function NavBar({ pageIntro, name }) {
  return (
    <div className=" flex justify-between items-center bg-[#F7F7F7] w-full">
      <p className=" flex text-[40px] font-normal">
        {pageIntro}
        <p className=" text-[40px] font-medium">{name}</p>
      </p>
      <div className=" flex justify-center items-center gap-2">
        <img src="../../../images/Avatar.png" alt="avatar" />
        <div className=" bg-[#FFE7DF] p-1 flex justify-center items-center rounded-lg h-8 w-8">
          <DownVector />
        </div>
      </div>
    </div>
  );
}

export default NavBar;
