import React, { useState } from "react";
import { ItemsCard, OrderStatus } from "../Invoice";
import Button from "../../components/common/button";
import { ReviewModal } from "../../components/modals";
import { ThemeContext } from "../../App";
import Head from "../../components/header/head";
import Menucontainer from "../../components/Layout/menucontainer";
import { twMerge } from "tailwind-merge";

function Orders() {
  const [modal, setModal] = useState(false);
  let { cart } = React.useContext(ThemeContext);
  const full = twMerge("min-h-[184px] h-fit");
  const empty = twMerge("h-[284px]");
  function hideModal() {
    setModal(false);
  }
  function showModal() {
    setModal(true);
  }
  const subtotal = cart.reduce((acc, item) => {
    acc += item.qty * item.price;
    return acc;
  }, 0);
  
  const vat = (7.5 / 100) * subtotal;
  const total = Math.floor(vat + subtotal);
  return (
    <div className=" w-full bg-[#F7F7F7] pt-12 relative">
      <Head
        pageTitle={"Foodswiipe Menu | Order"}
        metaDescription={"Foodswiipe Menu | Order"}
      />
      <Menucontainer>
        <div className=" w-full bg-[#F7F7F7] px-[6.106%]">
          <p className="text-black font-bold text-3xl mb-6">My Order</p>
          <div className=" bg-white p-6 rounded-2xl mb-6">
            <div className=" w-full flex items-center justify-between mb-2">
              <p className=" font-semibold">Cart Items</p>
            </div>
            <div
              className={`${
                cart.length <= 0 ? empty : full
              } flex flex-col gap-2 pt-4 border-t border-solid border-[rgba(0, 0, 0, 0.1)]`}
            >
              {cart.map((item) => {
                return (
                  <ItemsCard
                    itemName={item.name}
                    itemQty={item.qty}
                    cartList={true}
                    itemPrice={`N${item.price * item.qty}`}
                  />
                );
              })}
              <div className=" flex justify-between">
                <p className=" text-base">Subtotal</p>
                <div className=" flex items-center gap-8">
                  <p>N{subtotal}</p>
                </div>
              </div>
              <div className=" flex justify-between">
                <p className=" text-base">Total</p>
                <div className=" flex items-center gap-8">
                  <p>N{total}</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" bg-white p-6 rounded-2xl mb-6">
            <div className=" flex flex-col gap-2">
              <OrderStatus info={"Order Status"} details={"Preparing"} />
              <OrderStatus info={"Order Status"} details={"Cooking"} />
              <OrderStatus
                info={"Order Status"}
                details={"Coming to your Table"}
              />
            </div>
          </div>
          <div
            onClick={showModal}
            className=" flex w-full gap-4 items-center py-6"
          >
            <Button type={"button"} title="Rate Restaurant" variant="primary" />
          </div>
        </div>

        {modal ? (
          <div className="w-full fixed bottom-0 left-0 right-0 ">
            <ReviewModal click={hideModal} />
            {/* <RecommendationModal click={hideModal} /> */}
          </div>
        ) : null}
      </Menucontainer>
    </div>
  );
}

export default Orders;
