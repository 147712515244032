import React from "react";

function FoodTab({ title, classname, id, index, setActiveCategory }) {
  return (
    <button onClick={() => setActiveCategory(index)} className={classname}>
      {title}
    </button>
  );
}

export default FoodTab;
