import React from "react";
import { AppStore, CancelIcon, PlayStore, StarIcon } from "../../icons";
import Button from "../common/button";
import { Link, useNavigate } from "react-router-dom";
import Menucontainer from "../Layout/menucontainer";
import {usePaystackPayment} from "react-paystack";

export function PaymentModal({ click , makeOrder, amount}) {
  const config = {
    reference: (new Date()).getTime().toString(),
    email: `${process.env.PAYSTACK_CONFIG_EMAIL}`,
    amount: amount * 100,
    publicKey: `${process.env.PAYSTACK_PUBLIC_KEY}`
  };

  const navigate = useNavigate()

  const onSuccess = async(reference) => {
    await makeOrder(reference.reference)
    navigate('/my-order')
  }

  const onClose = () => {
    alert("Are you sure you want to close?")
  }

  const initializePayment = usePaystackPayment(config)
  
  return (
    <Menucontainer>
      <div className=" w-full px-[6.106%] bg-[#F7F7F7] pt-8 pb-10 rounded-t-2xl shadow-5xl ">
        <div className="w-full flex items-center justify-between mb-4">
          <p className=" text-black font-bold text-2xl">Payment</p>
          <div
            className=" h-8 w-8 bg-[#FFF0EB] rounded-xl flex items-center justify-center"
            onClick={click}
          >
            <CancelIcon />
          </div>
        </div>
        <form className="w-full flex flex-col gap-4">
          <Link to="/my-order">
            <Button type={"button"} title="Make Payment" variant="primary" />
          </Link>
          <div className=" w-full flex flex-col gap-4 pt-4 border-t border-solid border-[rgba(0, 0, 0, 0.1)]">
            <Button variant="secondary" type={"button"} title="Pay with Paystack" 
              onClick={() => {
                initializePayment({onSuccess, onClose, config})
              }}
            />
          </div>
        </form>
      </div>
    </Menucontainer>
  );
}

export function ReviewModal({ click }) {
  return (
    <Menucontainer>
      <div className=" w-full px-[6.106%] bg-[#F7F7F7] pt-8 pb-10 rounded-t-2xl shadow-[0px_4px_16px_0px_rgba(0, 0, 0, 0.25)] ">
        <div className="w-full flex items-center justify-between mb-4">
          <p className=" text-black font-bold text-2xl">Rate this Restaurant</p>
          <div
            className=" h-8 w-8 bg-[#FFF0EB] rounded-xl flex items-center justify-center"
            onClick={click}
          >
            <CancelIcon />
          </div>
        </div>
        <form className="w-full flex flex-col gap-6 outline-none">
          <div className="w-full flex flex-col relative">
            <label className=" font-normal absolute -top-3 left-4">
              Feedback/Comment
            </label>
            <textarea
              placeholder={"Write a review"}
              className={` bg-[#FFFFFF] rounded-lg w-full h-32 p-4 outline-none`}
            ></textarea>
          </div>
          <div className="w-full flex items-center justify-center">
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </div>
          <Button type={"button"} title="Submit Review" variant="primary" />
        </form>
      </div>
    </Menucontainer>
  );
}

export function RecommendationModal({ click }) {
  return (
    <Menucontainer>
      <div className=" w-full px-[6.106%] bg-[#F7F7F7] pt-8 pb-10 rounded-t-2xl shadow-[0px_4px_16px_0px_rgba(0, 0, 0, 0.25)] ">
        <div className="w-full flex items-center justify-between mb-4">
          <p className=" text-black font-bold text-2xl">Recommendation</p>
          <div
            className=" h-8 w-8 bg-[#FFF0EB] rounded-xl flex items-center justify-center"
            onClick={click}
          >
            <CancelIcon />
          </div>
        </div>
        <div className=" bg-white p-6 rounded-2xl mb-12">
          <p className=" font-medium text-2xl mb-2">Download FoodSwipe</p>
          <p>
            Download our mobile app for a more personalised and convenient
            dining experience at your favourite restaurants in the future.
          </p>
        </div>
        <div className=" w-full flex justify-center items-center gap-4">
          <div className=" w-1/2 bg-[#F5853F] flex items-center justify-center gap-2 rounded-2xl h-16 p-4">
            <AppStore />
            <div className=" text-white">
              <p className=" text-xs">Download on</p>
              <p className=" font-bold">App Store</p>
            </div>
          </div>
          <div className=" w-1/2 bg-[#F5853F] flex items-center justify-center gap-2 rounded-2xl h-16 p-4">
            <PlayStore />
            <div className=" text-white">
              <p className=" text-xs">Download on</p>
              <p className=" font-bold">Google Play</p>
            </div>
          </div>
        </div>
      </div>
    </Menucontainer>
  );
}
