import React, { useState } from "react";
import { AppLogo } from "../../icons";
import Button from "../../components/common/button";
import { Link } from "react-router-dom";
import Head from "../../components/header/head";
import Menucontainer from "../../components/Layout/menucontainer";

function Register() {
  const [firstName, setFirstname] = useState("");
  if (firstName.length > 0) {
    localStorage.setItem("user", firstName);
  }
  return (
    <Menucontainer>
      <div className="w-full flex flex-col items-center justify-center rounded-3xl bg-[#F7F7F7] bg-splash-blob pt-28">
        <Head
          pageTitle={"Foodswiipe Menu | Register"}
          metaDescription={"Foodswiipe Menu | Register"}
        />
        <div className="  w-full flex flex-col items-center justify-center mb-12 px-[6.106%] pb-6">
          <div className="bg-[#ffffff] rounded-2xl flex justify-center items-center w-[163px]  mb-6 h-[163px]">
            {/* <img src="../../../images/logo-bg.png" alt="logo" /> */}
            <AppLogo />
          </div>
        </div>
        <div className=" mb-12">
          <div className="text-[#F5853F] text-2xl font-semibold text-center">
            <p>Welcome to</p>
            <p>Edibles and Delicacies</p>
          </div>

          <p className="text-[#808080] font-light ">
            Lets personalise your experience
          </p>
        </div>
        <div className="w-full flex flex-col items-center justify-between">
          <div className="w-full px-[6.106%] flex flex-col relative">
            <label className=" font-normal absolute -top-3 left-11">
              First Name
            </label>
            <input
              value={firstName}
              onInput={(e) => setFirstname(e.target.value)}
              type={"text"}
              placeholder={"Your Name"}
              className={` bg-[#FFFFFF] rounded-lg w-full outline-none h-14 p-4`}
            />
          </div>
          <div className=" px-[6.106%] mt-8 flex w-full gap-4 items-center py-6">
            <Link to="/menu" className="w-full">
              <Button
                disabled={firstName.length > 0 ? false : true}
                type={"button"}
                title="Open Menu"
                variant="primary"
              />
            </Link>
          </div>
        </div>
      </div>
    </Menucontainer>
  );
}

export default Register;
