import React, { useState } from "react";
import { CartIcon } from "../../icons";
import FoodTab from "../../components/food-tab";
import Button from "../../components/common/button";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Head from "../../components/header/head";
import Menucontainer from "../../components/Layout/menucontainer";
import useSWR from "swr";
import { fetcher } from "../../utils";
import FoodCard from "../../components/common/foodCard";

function Home() {
    const [mealDisplay, setMealDisplay] = useState(0);
    const [mealId, setMealId] = useState();
    const [activeCategory, setActiveCategory] = useState(null);
    const [pop, setPop] = useState(false);
    // const [activeCategoryId, setActiveCategoryId] = useState(null)

    const [cartStatement, setCartStatement] = useState(false);
    let { cartItems, cart, setCart } = React.useContext(ThemeContext);

    const BASE_URL = process.env.REACT_APP_SERVER_URL;

    const bizId = localStorage.getItem("bizId");
    const username = localStorage.getItem("user");

    const { data, error, isLoading } = useSWR(
        `${BASE_URL}food?businessId=${bizId}`,
        fetcher
    );

    const {
        data: CategoryData,
        // error: CategoryError,
        // isLoading: CategoryLoading,
    } = useSWR(`${BASE_URL}category?businessId=${bizId}`, fetcher);

    function prevMeal() {
        setCartStatement(false);
        if (mealDisplay === 0) {
            setMealDisplay(0);
        } else {
            setMealDisplay((prev) => prev - 1);
        }
    }
    function nextMeal() {
        setCartStatement(false);

        if (mealDisplay === data.data.length - 1) {
            setMealDisplay(data.data.length - 1);
        } else {
            setMealDisplay((prev) => prev + 1);
        }
    }

    // let timerId = setInterval(() => setPop(true), 300)
    if (pop) {
        setTimeout(() => {
            // clearInterval(timerId)
            setPop(false);
        }, 2000);
    }

    function addToCart() {
        let matchMeal = cartItems.find((item) => item?.id === mealId);
        if (matchMeal === undefined) {
            alert("Add Quantity");
        } else {
            setPop(true);
            setCart((prev) => {
                let itemExist = prev.find((item) => item?.id === mealId);
                setCartStatement(true);
                if (itemExist) {
                    return [...prev];
                } else {
                    return [...prev, matchMeal];
                }
            });
        }
    }

    const cartCount = cart.length;
    // const cartCount = cart.reduce((counter, obj) => {
    //   counter += obj.qty;
    //   return counter;
    // }, 0);
    const inactiveTabStyle =
        "flex capitalize w-max items-center justify-center text-base px-3 py-4 bg-[#FFFFFF]  text-[rgba(7, 7, 7, 0.60)] font-light text-center rounded-3xl";

    const activeTabStyle =
        "flex capitalize w-max items-center justify-center text-base px-3 py-4 bg-[#FFCDBC] text-[#070707] text-center rounded-3xl";
    return (
        <Menucontainer>
            {isLoading ? (
                "loading.."
            ) : data ? (
                <div className="w-full bg-[#F7F7F7] text pt-12 rounded-3xl">
                    <Head
                        pageTitle={"Foodswiipe Menu | Home"}
                        metaDescription={"Foodswiipe Menu | Home"}
                    />
                    <div className="  w-full px-[6.106%]">
                        <div className=" flex justify-between items-center py-4">
                            <p className=" text-black font-bold text-3xl">
                                Hi {username}
                            </p>
                            <Link to="/invoice">
                                <div className=" h-11 w-11 relative bg-[#FFF0EB] rounded-2xl flex items-center justify-center">
                                    <CartIcon />
                                    {cartCount !== 0 ? (
                                        <p className=" h-6 w-6 text-white absolute top-[-8px] right-[-8px] rounded-full bg-[#E25C07] flex items-center justify-center">
                                            {cartCount}
                                        </p>
                                    ) : null}
                                </div>
                            </Link>
                        </div>
                        <div className=" w-full mb-8">
                            <div className="flex gap-2 items-center overflow-x-scroll no-scrollbar">
                                {CategoryData
                                    ? CategoryData.data.map(
                                          (category, index) => (
                                              <FoodTab
                                                  index={index}
                                                  key={category.id}
                                                  title={category.name}
                                                  classname={
                                                      activeCategory === index
                                                          ? activeTabStyle
                                                          : inactiveTabStyle
                                                  }
                                                  setActiveCategory={
                                                      setActiveCategory
                                                  }
                                              />
                                          )
                                      )
                                    : null}

                                {/* <FoodTab title={"Main Dish"} classname={inactiveTabStyle} />
								<FoodTab title={"Main Dish"} classname={inactiveTabStyle} />
								<FoodTab title={"Main Dish"} classname={inactiveTabStyle} />
								<FoodTab title={"Main Dish"} classname={inactiveTabStyle} /> */}
                            </div>
                        </div>
                        <div className=" w-full">
                            {data.data.map((product, index) => {
                                if (index === mealDisplay) {
                                    return (
                                        <FoodCard
                                            key={index}
                                            product={product}
                                            id={index}
                                            nextMeal={nextMeal}
                                            prevMeal={prevMeal}
                                            setMealId={setMealId}
                                            data={data}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="px-[6.106%] flex w-full gap-4 items-center py-4">
                        <Button
                            // disabled={quantity <= 0 ? true : false}
                            type={"button"}
                            title={
                                cartStatement ? "Added to cart" : "Add to cart"
                            }
                            onClick={addToCart}
                            variant="primary"
                        />
                        <Link to="/invoice" className="w-full">
                            <Button
                                type={"button"}
                                title="Proceed"
                                variant="secondary"
                            />
                        </Link>
                    </div>
                </div>
            ) : error ? (
                <p>Somthing Went Wrong</p>
            ) : null}
            {pop ? (
                <div className="w-full ">
                    <PopCard />
                </div>
            ) : null}
        </Menucontainer>
    );
}

export default Home;

function PopCard() {
    return (
        <div className=" w-[450px] bg-white self-center flex justify-around p-6 fixed bottom-1 gap-4  mb-16 shadow-4xl rounded-2xl">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986"
                    stroke="#F5853F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M22 4L12 14.01L9 11.01"
                    stroke="#F5853F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <p>Order added to your cart. Continue shopping</p>
        </div>
    );
}
