import React, { useContext, useEffect, useState } from "react"
import { Add, GreaterThan, LessThan, Minus } from "../../icons"
import { ThemeContext } from "../../App"

function FoodCard({ id, product, prevMeal, nextMeal, setMealId, data }) {
	const [quantity, setQuantity] = useState(0)
	let { setCartItems, cart } = useContext(ThemeContext)

	useEffect(() => {
		const CartIds = []
		cart.map((item) => CartIds.push(item))
		const findItem = CartIds.find((item) => item.id === product.id)
		if (findItem) {
			setQuantity(findItem.qty)
		}
	}, [cart, product.id])
	function addItem(id) {
		setMealId(id)
		setQuantity((quantity) => quantity + 1)
		const qty = quantity + 1
		let selectedMeals = data.data.find((product) => product.id === id)

		// setCartItems((initialItemms) => [selectedMeals, ...initialItemms]);
		selectedMeals.qty = qty
		setCartItems((initialItems) => {
			if (initialItems.find((item) => item.id === id) == null) {
				return [...initialItems, selectedMeals]
			} else {
				return initialItems.map((item) => {
					if (item.id === id) {
						return { ...item, qty: qty }
					} else {
						return item
					}
				})
			}
		})
	}

	function removeItem(id) {
		setQuantity((prev) => {
			if (prev > 0) {
				return prev - 1
			} else return prev
		})
		setCartItems((initialItems) => {
			if (initialItems.find((item) => item.id === id)?.qty === 1) {
				return initialItems.filter((item) => item.id !== id)
			} else {
				return initialItems.map((item) => {
					if (item.id === id) {
						return { ...item, qty: item.qty - 1 }
					} else {
						return item
					}
				})
			}
		})
	}
	return (
		<>
			<div className='w-full' key={id}>
				<img
					src={product.imageUrl}
					alt='meal'
					className='w-full h-[270px] rounded-t-3xl'
				/>
				<div className=' w-full bg-[#FEF0E7] border border-solid border-a p-6 rounded-b-3xl'>
					<p className='  text-black mb-3'>{product.name}</p>
					<div className=' w-full flex justify-between items-center'>
						<p className=' text-black font-semibold'>
							{`N${product.price}`}/{product.portion}
						</p>
						<p className='  bg-[#F5853F] h-8 w-8 rounded-full flex items-center justify-center text-white text-center'>
							x{quantity}
						</p>
					</div>
				</div>
				<div className='w-full py-6 flex gap-9 items-center justify-between'>
					<button
						onClick={prevMeal}
						className='h-14 w-14 rounded-full bg-white flex items-center shadow-4xl  justify-center'>
						<LessThan />
					</button>
					<div className=' flex gap-4'>
						<button
							onClick={() => removeItem(product.id)}
							className='bg-white h-12 w-12 flex items-center justify-center rounded-lg'>
							<Minus />
						</button>
						<button
							onClick={() => {
								addItem(product.id)
							}}
							className='bg-white h-12 w-12 flex items-center justify-center rounded-lg'>
							<Add />
						</button>
					</div>
					<button
						onClick={nextMeal}
						className='h-14 w-14 rounded-full bg-white shadow-4xl flex items-center justify-center'>
						<GreaterThan />
					</button>
				</div>
			</div>
		</>
	)
}

export default FoodCard
