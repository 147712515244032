import React from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import Head from "../../../components/header/head";

function TableMonitor() {
  return (
    <div>
      <Head
        pageTitle={"Foodswiipe Menu | Table-Monitor"}
        metaDescription={"Foodswiipe Menu | Table-Monitor"}
      />
      <KitchenLayout pageIntro={"Table Monitor"}>
        <h1 className="text-center bg-[#F7F7F7]">TableMonitor</h1>
      </KitchenLayout>
    </div>
  );
}

export default TableMonitor;
