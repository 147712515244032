import React from "react";
import NavBar from "./navbar";
import SidePanel from "./sidePanel";

function KitchenLayout({ pageIntro, name, children }) {
  return (
    <div className="w-full flex bg-[#F7F7F7]">
      <SidePanel />
      <div className=" w-full bg-[#F7F7F7] pt-[53px] pl-[3.41%] pr-[14.77%] gap-16 flex flex-col ml-[14.4%]">
        <NavBar pageIntro={pageIntro} name={name} />
        <div className=" bg-[#F7F7F7]">{children}</div>
      </div>
    </div>
  );
}

export default KitchenLayout;
