import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Invoice from "../pages/Invoice";
import Home from "../pages/Home";
import Scan from "../pages/QRScan";
import Register from "../pages/Register";
import Orders from "../pages/Orders";
import TableMonitor from "../pages/Kitchen/Table-monitor";
import EditInvoice from "../pages/Edit-invoice";
import ErrorPage from "../pages/Error/Error";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Scan/>, errorElement : <ErrorPage message="There was an issue with the QR code. Please scan again."/> },
      {
        path: "invoice",
        element: <Invoice />,
      },
      {
        path: "menu",
        element: <Home />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "my-order",
        element: <Orders />,
      },
      {
        path: "edit-invoice",
        element: <EditInvoice />,
      },
      {
        path: "kitchen/table-monitor",
        element: <TableMonitor />,
      },
      {
        path : "error",
        element : <ErrorPage message="There was an issue with the QR code. Please scan again."/>
      }
    ],
  },
]);
