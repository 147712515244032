import React from "react";
import SidebarTab from "./sidebarTab";
import { useLocation } from "react-router-dom";

function SidePanel() {
  const { pathname } = useLocation();

  return (
    <div className=" w-[14.444%] pt-16 pl-6 h-screen bg-[#FFFFFF] fixed flex-col justify-center items-center overflow-y-scroll no-scrollbar">
      <p className=" font-bold text-3xl text-[#F7670D] self-center mb-16">
        FoodSwipe
      </p>
      <div className=" w-full flex flex-col gap-4">
        <SidebarTab
          path={"/kitchen/dashboard"}
          location={pathname}
          title={"Dashoard"}
          inactiveIcon={"../../../images/grid.svg"}
          activeIcon={"../../../images/grid-active.svg"}
        />
        <SidebarTab
          path={"/kitchen/table-monitor"}
          title={"Table Monitor"}
          location={pathname}
          inactiveIcon={"../../../images/grid.svg"}
          activeIcon={"../../../images/grid-active.svg"}
        />
        <SidebarTab
          path={"/kitchen/orders"}
          title={"Orders"}
          location={pathname}
          inactiveIcon={"../../../images/grid.svg"}
          activeIcon={"../../../images/grid-active.svg"}
        />
        <SidebarTab
          path={"/kitchen/generator"}
          title={"Generator"}
          location={pathname}
          inactiveIcon={"../../../images/grid.svg"}
          activeIcon={"../../../images/grid-active.svg"}
        />
        <SidebarTab
          path={"/kitchen/settings"}
          title={"Settings"}
          location={pathname}
          inactiveIcon={"../../../images/grid.svg"}
          activeIcon={"../../../images/grid-active.svg"}
        />
      </div>
    </div>
  );
}

export default SidePanel;
